import {
  ref, watch, computed, getCurrentInstance,
} from '@vue/composition-api'

// import { useToast } from 'vue-toastification/composition'
import { convertISODateTime } from '@/@core/utils/filter'
import store from '@/store'
import { apiCustomer } from '@/api'

import useToast from '@useToast'

// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useSaleReportHandle() {
  const { toastSuccess, toastError } = useToast()

  const vm = getCurrentInstance().proxy
  // Loading
  const loading = ref(false)
  // Refs
  const refSaleReportListTable = ref(null)

  // Filter
  const sizePerPage = ref(40)
  const currentPage = ref(1)
  const totalItems = ref(0)
  const sortBy = ref(null) // createdAt || updatedAt
  const isSortDirDesc = ref(true) //  ASC || DESC
  const fixFlightType = ref('')
  const searchText = ref(null)
  // filtersParams includes: startDate, endDate, agencyCode, flightType, bookingCode,
  let filtersParams = {}

  const dataMeta = computed(() => {
    const localItemsCount = refSaleReportListTable.value
      ? refSaleReportListTable.value.localItems.length
      : 0
    return {
      from:
        sizePerPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: sizePerPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalItems.value,
    }
  })

  const saleReportItems = ref([])

  const refetchData = ($event = {}, page = 1) => {
    filtersParams = { ...$event }
    currentPage.value = page
    refSaleReportListTable.value.refresh()
  }

  const clearFilter = () => {
    searchText.value = null
    refetchData()
  }

  watch([currentPage, sizePerPage, sortBy, isSortDirDesc], () => {
    if (!loading.value) {
      refetchData(filtersParams, currentPage.value)
    }
  })

  const fetchSaleReports = (ctx, callback) => {
    loading.value = true

    if (fixFlightType.value) filtersParams.flightType = fixFlightType.value

    if (Object.keys(filtersParams).length === 0) {
      filtersParams = {
        startDate: convertISODateTime(new Date()).dateFilter,
        endDate: convertISODateTime(new Date()).dateFilter,
      }
    }
    store
      .dispatch('app-sale-report/fetchSaleReports', {
        ...filtersParams,
        searchText: searchText.value || undefined,
        sortBy: sortBy.value ? sortBy.value : 'issueDate',
        sortDirection: isSortDirDesc.value ? 'DESC' : 'ASC',
        size: sizePerPage.value,
        page: currentPage.value,
      })
      .then(response => {
        totalItems.value = response.data.total
        saleReportItems.value = response.data.items || []
        callback(saleReportItems.value)
      })
      .catch(error => {
        toastError({
          title: 'messagesList.error',
          content: `${error.message || error}`,
        })
      })
      .finally(() => {
        loading.value = false
      })
  }

  const exportSaleReport = () => {
    loading.value = true
    vm.$bvModal.show('modal-api-loading')
    store.dispatch('app/setTextLoading', 'Đang tải dữ liệu, vui lòng chờ...')
    store
      .dispatch('app-sale-report/exportSaleReport', {
        ...filtersParams,
        page: currentPage.value,
        size: sizePerPage.value,
        sortBy: sortBy.value ? sortBy.value : 'issueDate',
        sortDirection: isSortDirDesc.value ? 'DESC' : 'ASC',
        searchText: searchText.value || undefined,
      })
      .then(() => {
        toastSuccess({
          title: 'messagesList.success',
          content: 'saleReport.msg.exportSuccess',
        })
      })
      .catch(() => {
        toastError({
          title: 'messagesList.error',
          content: 'saleReport.msg.exportError',
        })
      })
      .finally(() => {
        loading.value = false
        vm.$bvModal.hide('modal-api-loading')
        store.dispatch('app/setTextLoading', null)
      })
  }

  // ANCHOR REFUND TICKET
  const fetchRefundTicket = (ctx, callback) => {
    loading.value = true

    if (fixFlightType.value) filtersParams.flightType = fixFlightType.value

    if (Object.keys(filtersParams).length === 0) {
      filtersParams = {}
    }

    store
      .dispatch('app-sale-report/fetchRefundTicket', {
        ...filtersParams,
        searchText: searchText.value || undefined,
        sortBy: sortBy.value ? sortBy.value : 'createdAt',
        sortDirection: isSortDirDesc.value ? 'DESC' : 'ASC',
        size: sizePerPage.value,
        page: currentPage.value,
      })
      .then(response => {
        totalItems.value = response.data.total
        saleReportItems.value = response.data.items || []
        callback(saleReportItems.value)
      })
      .catch(error => {
        toastError({
          title: 'messagesList.error',
          content: `${error.message || error}`,
        })
      })
      .finally(() => {
        loading.value = false
      })
  }

  // SECTION CREATE MANUALLY
  function fetchAgenciesChild(id) {
    return store.dispatch('app-sale-report/fetchAgenciesChild', id)
  }

  function createSaleReportManually(payload) {
    loading.value = true
    return new Promise((resolve, reject) => store
      .dispatch('app-sale-report/createSaleReportManually', payload)
      .then(res => {
        resolve(res)
        toastSuccess({
          title: 'messagesList.success',
          content: 'messagesList.saleReport.createManually.success',
        })
      })
      .catch(error => {
        reject(error)
        toastError({
          title: 'messagesList.error',
          content: 'messagesList.saleReport.createManually.error',
        })
      })
      .finally(() => {
        loading.value = false
      }))
  }

  function deleteSaleReportManually(id) {
    loading.value = true
    return store
      .dispatch('app-sale-report/deleteSaleReportManually', id)
      .then(() => {
        toastSuccess({
          title: 'messagesList.success',
          content: 'messagesList.saleReport.deleteManually.success',
        })
      })
      .catch(() => {
        toastError({
          title: 'messagesList.error',
          content: 'messagesList.saleReport.deleteManually.error',
        })
      })
      .finally(() => {
        loading.value = false
        refetchData()
      })
  }
  function getCustomersByAgency(agencyId, searchText) {
    return new Promise((resolve, reject) => {
      apiCustomer
        .getCustomersByAgency(agencyId, { searchText })
        .then(response => { resolve(response.data.items) })
        .catch(e => {
          reject(e)
          toastError({
            title: 'messagesList.error',
            content: `${e.message || e}`,
          })
        })
        .finally(() => {})
    })
  }

  function getSaleReportById(id) {
    loading.value = true
    return store
      .dispatch('app-sale-report/getSaleReportById', id)
      .catch(() => {
        toastError({
          title: 'messagesList.error',
          content: 'messagesList.employee.errorFetching',
        })
      })
      .finally(() => {
        loading.value = false
      })
  }

  function updateSaleReport(payload) {
    loading.value = true
    return new Promise((resolve, reject) => store
      .dispatch('app-sale-report/updateSaleReport', payload)
      .then(res => {
        resolve(res)
        toastSuccess({
          title: 'messagesList.success',
          content: 'messagesList.saleReport.updateManually.success',
        })
      })
      .catch(error => {
        reject(error)
        toastError({
          title: 'messagesList.error',
          content: 'messagesList.saleReport.updateManually.error',
        })
      })
      .finally(() => {
        loading.value = false
      }))
  }

  // !SECTION

  // SECTION RETAIL
  const fetchRetailSaleReports = (ctx, callback) => {
    loading.value = true

    if (fixFlightType.value) filtersParams.flightType = fixFlightType.value

    if (Object.keys(filtersParams).length === 0) {
      filtersParams = {
        startDate: convertISODateTime(new Date()).dateFilter,
        endDate: convertISODateTime(new Date()).dateFilter,
      }
    }
    store
      .dispatch('app-sale-report/fetchRetailSaleReports', {
        ...filtersParams,
        searchText: searchText.value || undefined,
        sortBy: sortBy.value ? sortBy.value : 'issueDate',
        sortDirection: isSortDirDesc.value ? 'DESC' : 'ASC',
        size: sizePerPage.value,
        page: currentPage.value,
      })
      .then(response => {
        totalItems.value = response.data.total
        saleReportItems.value = response.data.items || []
        callback(saleReportItems.value)
      })
      .catch(error => {
        // console.error('error', error)
        toastError({
          title: 'messagesList.error',
          content: `${error.message || error}`,
        })
      })
      .finally(() => {
        loading.value = false
      })
  }
  // !SECTION

  return {
    // Loading
    loading,
    // Refs
    refSaleReportListTable,

    // Filters
    sizePerPage,
    currentPage,
    dataMeta,
    sortBy,
    isSortDirDesc,
    // Extra Filters
    fixFlightType,
    searchText,

    totalItems,
    saleReportItems,
    refetchData,
    clearFilter,

    // DISPATCH STORE
    fetchSaleReports,
    exportSaleReport,

    // REFUND TICKET
    fetchRefundTicket,

    // CREATE MANUALLY
    fetchAgenciesChild,
    createSaleReportManually,
    deleteSaleReportManually,
    getCustomersByAgency,
    getSaleReportById,
    updateSaleReport,

    fetchRetailSaleReports,
  }
}

export function exportSaleReportTemplate() {
  store
    .dispatch('app-sale-report/exportSaleReportTemplate', {
      language: 'en',
    })
    .then(() => {
      this.$root.toastSuccess('Export successfully')
    })
    .catch(error => {
      console.error({ error })
      this.$root.toastError('Error export sale report template')
    })
}

// useForInvoice:  dùng để hiển thị nội dung cho mỗi số vé trong bảng dịch vụ hàng hoá của hoá đơn
// RT: HAN SGN HAN
// MC: HAN SGN // PQC HAN
export function convertShortenTrips(trips, useForInvoice = false) {
  if (!trips) return []
  if (useForInvoice) {
    const arrTrips = trips.split(' - ').map(trip => {
      const segments = trip.split(' | ').map(seg => seg.replaceAll('-', '').slice(0, 6))
      return `${segments[0].slice(0, 3)} ${segments[segments.length - 1].slice(3, 6)}`
    })
    if (arrTrips.length === 2
      && arrTrips[0].slice(0, 3) === arrTrips?.[1].slice(4, 7)
      && arrTrips[0].slice(4, 7) === arrTrips?.[1].slice(0, 3)
    ) {
      return [`${arrTrips[0].slice(0, 3)} ${arrTrips[0].slice(4, 7)} ${arrTrips[0].slice(0, 3)}`]
    }
    return arrTrips
  }
  return trips.split(' - ').join(' | ').split(' | ').map(trip => trip.replaceAll('-', '').slice(0, 6))
}
